import React from "react"
import "../styles/pageLinks.css"
import ArrowRight from "../images/arrowRight.js"

export default function PageLinks(props) {

    console.log(props);

    function renderLinks(links) {
        return links.map((item, i) => {
            if (i % 2 === 0) {
                return (
                    <div key={i} className="pageLink">
                        <div className="pageLinkTextContainer">
                            <div className="pageLinkTitle">{item.title}</div>
                            <a href={item.link} className="pageLinkButton">
                                {item.buttonText}
                                <ArrowRight color="#20265E"></ArrowRight>
                            </a>
                        </div>
                        <div className="pageLinkImageContainer">
                            <img className="pageLinkImage" src={item.image} />
                        </div>
                    </div>
                )
            }
            else if (i % 2 === 1) {
                return (
                    <div key={i} className="pageLink pageLink__odd">
                        <div className="pageLinkImageContainer">
                            <img className="pageLinkImage" src={item.image} />
                        </div>
                        <div className="pageLinkTextContainer">
                            <div className="pageLinkTitle">{item.title}</div>
                            <a href={item.link} className="pageLinkButton">
                                {item.buttonText}
                                <ArrowRight color="#20265E"></ArrowRight>
                            </a>
                        </div>
                    </div>
                )
            }
        });
    }

    return (
        <div className="pageLinks">
            <div className="pageLinksContainer">
                {renderLinks(props.links)}
            </div>
        </div>
    )
}