import React from "react"
import "../styles/responsibilities.css"

export default function Responsibilities(props) {

    function renderList(list) {
        return list.map((item, i) => {
            if ((i + 1) % 3 === 0 && i != 0) {
                return (
                    <div key={i} className="responsibilitiesListItem responsibilitiesListItem__third">
                        <div className="responsibilitiesItemImage">
                            <img src={item.image} alt="" />
                        </div>
                        <div className="responsibilitiesItemTitle">{item.title}</div>
                        <div className="responsibilitiesItemText">{item.text}</div>
                    </div>
                )
            } else {
                return (
                    <div key={i} className="responsibilitiesListItem">
                        <div className="responsibilitiesItemImage">
                            <img src={item.image} alt="" />
                        </div>
                        <div className="responsibilitiesItemTitle">{item.title}</div>
                        <div className="responsibilitiesItemText">{item.text}</div>
                    </div>
                )
            }
        })
    }

    return (
        <div className="responsibilities">
            <div className="responsibilitiesContainer">
                <div className="responsibilitiesTitle">{props.responsibilities.title}</div>
                <div className="responsibilitiesList">{renderList(props.responsibilities.list)}</div>
            </div>
        </div>
    )
}