import React from "react"
import "../styles/bios.css"

export default function Bios(props) {

    function renderBios(list) {
        return list.map((item, i) => (
            <div key={i} className="biosItem">
                <div className="biosImage"><img src={item.image} alt="" /></div>
                <div className="biosBio">
                    <div className="biosName">{item.name}</div>
                    <div className="biosText">{item.status}</div>
                </div>
                <div className="biosContacts">
                    <div className="biosText">{item.phone}</div>
                    <div className="biosText">{item.email}</div>
                </div>
            </div>
        ))
    }

    return (
        <div className="bios">
            <div className="biosContainer">
                {renderBios(props.bios)}
            </div>
        </div>
    )
}