import * as React from "react"
import { graphql } from 'gatsby'
import "../styles/main.css"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import Hero from "../components/hero.js"
import Slider from "../components/slider.js"
import PageLinks from "../components/pageLinks.js"
import BasicBanner from "../components/basicBanner.js"
import Features from "../components/features.js"
import Responsibilities from "../components/responsibilities.js"
import CtaBanner from "../components/ctaBanner.js"
import Bios from "../components/bios.js"

// markup
const IndexPage = (props) => {

  const intro = props.props.intro
  const trusters = props.props.trusters
  const links = props.props.links
  const bannerText = props.props.bannerText
  const features = props.props.features
  const responsibilities = props.props.responsibilities
  const bigBanner = props.props.bigBanner
  const bios = props.props.bios

  return (
    <main>
      <Header page={0}></Header>
      <Hero title={intro.title} text={intro.text} image={intro.image} orientation="vertical"></Hero>
      {/* <Slider trusters={trusters}></Slider> */}
      <PageLinks links={links}></PageLinks>
      <BasicBanner text={bannerText}></BasicBanner>
      <Features features={features}></Features>
      <Responsibilities responsibilities={responsibilities}></Responsibilities>
      <CtaBanner bigBanner={bigBanner}></CtaBanner>
      <Bios bios={bios}></Bios>
      <Footer></Footer>
    </main>
  )
}

const IndexPageExport = ({ data }) => {
  const params = data.allMdx.nodes[0].frontmatter
  // console.log(props)
  return (
    <IndexPage props={params}></IndexPage>
  )
}

export default IndexPageExport

export const pageQuery = graphql`
query HomePageQuery {
  allMdx(filter: {frontmatter: {templateKey: {eq: "home"}}}) {
    nodes {
      frontmatter {
        intro {
          image
          text
          title
        }
        trusters {
          title
          list {
            image
            title
          }
        }
        links {
          buttonText
          image
          title
          link
        }
        bannerText
        features {
          image
          featuresList {
            text
            title
          }
        }
        responsibilities {
          title
          list {
            title
            image
            text
          }
        }
        bigBanner {
          buttonText
          title
        }
        bios {
          email
          image
          name
          phone
          status
        }
      }
    }
  }
}

`