import React, { useState, useEffect } from "react"
import ArrowDown from "../images/arrowDown"
import useWindowDimensions from "./useWindowDimensions"
import "../styles/features.css"

export default function Features(props) {

    let temp = [];

    let { height, width } = useWindowDimensions()
    if (!width || width < 800) {
        temp = props.features.featuresList.map(() => false);
    } else {
        temp = props.features.featuresList.map(() => true);
    }
    let [prevWidth, setPrevWidth] = useState(0);
    let [visibleState, setVisibleState] = useState([...temp]);

    useEffect(() => {
        if (prevWidth === width) {
            return;
        }
        setPrevWidth(width);
        let isfalse = false;
        if (width > 800) {
            console.log("width > 800");
            for (let i = 0; i < visibleState.length; i++) {
                if (visibleState[i] === false) {
                    isfalse = true;
                }
            }
            if (isfalse) {
                let temp = [];
                for (let i = 0; i < visibleState.length; i++) {
                    temp.push(true);
                }
                setVisibleState([...temp]);
            }
        }
        else {
            console.log("width < 800");
            for (let i = 0; i < visibleState.length; i++) {
                if (visibleState[i] === true) isfalse = true;
            }
            if (isfalse) {
                let temp = [];
                for (let i = 0; i < visibleState.length; i++) {
                    temp.push(false);
                }
                console.log(temp);
                setVisibleState([...temp]);
            }
        }
    });

    function renderFeatures(list) {
        return list.map((item, i) => {
            if (i != 0) {
                return (
                    <label key={i} for={"checkbox" + i} className="feature">
                        <input className="featuresCheckbox" type="checkbox" id={"checkbox" + i} />
                        <div className="featuresTitle">
                            <div className="featuresTitleText">{item.title}</div>
                            <div className="featuresArrow"
                            ><ArrowDown></ArrowDown></div>
                        </div>
                        <div className="featuresText">{item.text}</div>
                    </label>
                )
            }
        })
    }

    // console.log(visibleState)
    return (
        <div className="features">
            <div className="featuresContainer">
                <div className="featuresHero">
                    <div className="featuresHeroImage"><img src={props.features.image} alt="" /></div>
                    <label className="featuresHeroText" for={"checkbox0"}>
                        <input className="featuresCheckbox" type="checkbox" id={"checkbox0"} />
                        <div className="featuresTitle">
                            <div className="featuresTitleText">{props.features.featuresList[0].title}</div>
                            <div className="featuresArrow"><ArrowDown></ArrowDown></div>
                        </div>
                        <div className={"featuresText"}>{props.features.featuresList[0].text}</div>
                    </label>
                </div>
                <div className="featuresSlides">
                    {renderFeatures(props.features.featuresList)}
                </div>
            </div>
        </div>
    )
}